import React, { Component,  } from "react";
import { 
  // Link, 
  withRouter } 
  from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { connect } from "react-redux";
// import {
//   // Dropdown,
//   // Button,
//   // ButtonGroup,
//   NavDropdown,
//   Nav,
// } from "react-bootstrap";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";

// Custom imports
import css from "../../styles/Header.module.css";
//import dashboard from "../../images/dashboard.png";
import { logout } from "../../actions/auth";
// import logo from "../../images/arboair-llogo.png";
import logo from "../../images/Horizontal_logotype_colour.png";
// import FeedbackModal from "../modals/FeedbackModal";
// import { sendFeedback } from "../../actions/feedback";
// import { MENU_ITEMS } from "../../data/menu_items";
import { headerNames } from "../../GLOBAR_PARAMETERS.js";


// This wrapper gives the current route,
// Done using https://stackoverflow.com/questions/42253277/react-router-v4-how-to-get-current-route

const Header = withRouter((props) => <HeaderHelper {...props} />);

export class HeaderHelper extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    // sendFeedback: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      // showFeedbackModal: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      showingVideo: true,
      isAuthenticated: this.props.auth.isAuthenticated
    };
  };

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    // console.log('Mount', this.props.auth.user)
  };

  componentDidUpdate() {
    // console.log('Update', this.props.auth.user)
    const { isAuthenticated, user } = this.props.auth;
    if ( this.state.isAuthenticated !== isAuthenticated ) {
      this.setState({
        isAuthenticated: isAuthenticated
      });
    };
    // console.log(typeof(window.Beacon) )
    if ( typeof(window.Beacon) !== 'undefined') {
      if ( typeof window.Beacon('info') == 'undefined' ) {
        // console.log('Create')
        window.Beacon(
          'init',
          '071645b3-17ff-423c-910e-fa470eb4f1a8'
        );
      };
      if ( isAuthenticated !== null) {
        // console.log(typeof window.Beacon('info'))
        // console.log(window.Beacon('info'))
        if ( typeof window.Beacon('info') !== 'undefined' && window.Beacon('info').visitor.email === '' && user !== null ) {
          // console.log('Prefill')
          // console.log(user)
          window.Beacon('identify', {
            name: user.given_name + ' ' + user.family_name,
            email: user.email
          });
        }
      } else {
        // console.log('Cleaning Beacon');
        window.Beacon('logout');
      };
      
      
    };
    // if ( typeof(window.Beacon) !== 'undefined' && this.state.isAuthenticated !== null ) {
    //   // console.log('Dragons')
    //   window.Beacon('identify', {
    //     name: user.given_name + ' ' + user.family_name,
    //     email: user.email
    //   });
    // if ( typeof(window.Beacon) !== 'undefined' ) {
    //   // console.log('Reseting Dragons')
    //   window.Beacon('reset')
    // };
  };

  onLogoutClicked = () => {
    this.props.logout();
  };
  render() {
    
    
    // const { isAuthenticated, user } = this.props.auth;
    
    

    if ( typeof(window.Beacon) !== 'undefined') {
      if ( window.Beacon('info') !== undefined ) {
        // console.log(window.Beacon('info').visitor)
      }
    };

    return (
      <div className={css.header}>
        <>
        <Helmet 
           script={[{
             type: 'text/javascript',
             innerHTML: '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
           }]}
         />
        </>
        {this.state.isAuthenticated ? (
          <>
          <React.Fragment>
            <img src={logo} alt='' style={{height: "60px" ,position:"fixed", top: "12px", left: "50%", marginLeft: "-100px"}}/>
            {this.state.matches ? <HeaderTitle {...this.props} /> : ""}
            {
              //!this.state.matches ? <DropdownMenu state={this.state} /> : ""
            }
            {this.props.auth.user ? (
              <div className={css.headerWrapper}>
                <div className={css.headerRight}>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </React.Fragment>
          </>
        ) : (
          <img src={logo} alt='' style={{ height: "60px" }} />
        )}
      </div>
    );
  }
}

// class DropdownMenu extends Component {
//   render() {
//     return (
//       <div className={css.headerTitle}>
//         <NavDropdown title={<i class="fas fa-bars"></i>}>
//           {MENU_ITEMS.map((item) => {
//             return (
//               <Nav.Item>
//                 <Nav.Link
//                   className={css.menuElement}
//                   activeClassName={css.menuElementActive}
//                   href={item.url}
//                 >
//                   <i class={item.icon}></i> {item.title}
//                 </Nav.Link>
//               </Nav.Item>
//             );
//           })}
//         </NavDropdown>
//       </div>
//     );
//   }
// }

class HeaderTitle extends Component {
  render() {
    let pathname = this.props.location.pathname.split("/")[1];

    return <div className={css.headerTitle}>{headerNames[pathname]}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
