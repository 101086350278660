import * as turf from "@turf/turf";
import L from "leaflet-geotiff";
import "leaflet.locatecontrol";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import shp from "shpjs";

// Local imports
import {
  ip,
  legendColors,
  legendNames,
  stressedColors,
  stressedNames,
} from "../../GLOBAR_PARAMETERS";
import { tokenConfig } from "../../actions/auth";
import { getOrder } from "../../actions/orders";
import {
  getMosaicLinkLeaflet,
  getTreeCountLinkLeaflet,
} from "../../actions/resultsdetailed";
import { getTilesetLink, getZipFileLeafleet } from "../../actions/webmapview";

// css imports
import css from "../../styles/MapSubPage.module.css";

//Store imports
import {
  sendErrorNotification,
  sendNotification,
} from "../../actions/notifications";

//Global variables
var info;
var legend;
var overlays = {};
var overlayControl;
var globalOrderID;
var tileFunction;

//Function to get class id
function class_id(properties) {
  if ("class_id" in properties) {
    return properties.class_id;
  } else {
    return properties.ID;
  }
}

export class LeafletMapResults extends Component {
  static propTypes = {
    point_type: PropTypes.string,
    orderID: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      center_long: 15.62137,
      center_lat: 58.410807,
      showLoadingModal: true,
      infoExists: false,
      legendExists: false,
      overlayExists: false,
    };
  }

  componentDidMount = async () => {
    globalOrderID = this.props.orderID;
    tileFunction = this.props.getTilesetLink;
    let orderDetail = await this.props.getOrder(globalOrderID);
    // console.log(orderDetail);
    if (orderDetail.status === 200) {
      if (this.mapRef.current !== null) {
        this.createMap(orderDetail);
      }
    }
  };

  // componentDidUpdate() {
  //   globalOrderID = this.props.orderID;
  //   tileFunction = this.props.getTilesetLink;
  //   console.log('Update')
  //   // console.log('Map ref', this.mapRef)
  //   // this.createMap();
  // }

  // componentWillUnmount() {

  // }

  createMap = (orderDetail) => {
    //Do not redraw map if page is refreshed
    try {
      this.myMap = L.map(this.mapRef.current, {
        center: [this.state.center_lat, this.state.center_long],
        zoom: 16,
        minZoom: 0,
        maxZoom: 18,
        zoomControl: false,
        tap: false,
        // zoomControl: true,
      });

      // This is just for displaying a normal map
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
      ).addTo(this.myMap);

      L.control
        .scale({
          maxWidth: 250,
        })
        .addTo(this.myMap);

      let coords = undefined;
      let centerCoords = undefined;
      let minCoords = undefined;
      let maxCoords = undefined;
      let mosaicBounds = undefined;
      try {
        coords = JSON.parse(orderDetail.data.coords);
        centerCoords = new L.LatLng(coords.center[0], coords.center[1]);
        minCoords = new L.LatLng(coords.min[0], coords.min[1]);
        maxCoords = new L.LatLng(coords.max[0], coords.max[1]);
        mosaicBounds = new L.latLngBounds(minCoords, maxCoords);
      } catch {
        coords = undefined;
      }

      this.addHomeAndZoom(this.myMap, centerCoords, this.myMap._zoom);

      L.control
        .locate({
          flyTo: true,
          returnToPrevBounds: true,
          keepCurrentZoomLevel: true,
          locateOptions: {
            enableHighAccuracy: true,
          },
        })
        .addTo(this.myMap);
      // centerCoords = [57.23842, 15.21871]

      //Shapefile
      if (orderDetail.data.shapefile !== "None") {
        // console.log('Shapefiles loading');
        this.props.children.props.state.shp_options.forEach(async (item) => {
          console.log("Shape", item.name);
          if (item.id !== 1) {
            let orderType = orderDetail.data.type_of_order;
            let damagesSpecies =
              (orderType >= 2 && orderType <= 7) ||
              (orderType >= 10 && orderType <= 15);
            let specieTypes =
              (orderType >= 4 && orderType <= 7) ||
              (orderType >= 12 && orderType <= 15);
            let render = true;
            if ((item.id === 4 || item.id === 7) && !damagesSpecies) {
              render = false;
            } else if (
              (item.id === 2 ||
                item.id === 3 ||
                item.id === 5 ||
                item.id === 6) &&
              !specieTypes
            ) {
              render = false;
            }
            if (render) {
              // console.log('In render');
              await this.props
                .getZipFileLeafleet(this.props.orderID, item.opt)
                .then((res) => {
                  // console.log('In res');
                  if (res.isAxiosError) {
                    console.error(res.response.statusText, res.response.status);
                    this.props.sendErrorNotification(res.response.statusText);
                  } else {
                    // console.log(res.data)
                    // if ( res.data === 'None' )
                    var blob = res.data;
                    blob
                      .arrayBuffer()
                      .then(async (buffer) => {
                        // console.log(item)
                        await this.addSHPToMap(buffer, item.name, centerCoords);
                      })
                      .catch((err) => {
                        console.log(err);
                        this.props.sendErrorNotification(err);
                      });
                  }
                });
            }
          }
        });
        // console.log('Shapefiles DONE');
      }

      // Mosaic TileLayer
      //NEED TO FIND BOUNDS FROM MOSAIC
      // console.log(orderDetail.data)
      if (orderDetail.data.tileset !== "None") {
        // console.log('Mosaic loading');
        var bounds = this.myMap.getBounds();
        this.addTiffToMap(bounds);
        // console.log('Mosaic DONE');
      }

      //Hex Grid
      // if (orderDetail.data.tree_count !== "None") {
      //   // console.log('Hexgrid loading');
      //   this.props.getTreeCountLinkLeaflet(this.props.orderID).then((res) => {
      //     if (res.isAxiosError) {
      //       console.error(res.response.statusText, res.response.status);
      //       this.props.sendErrorNotification(res.response.statusText);
      //       this.setState({
      //         hexDone: true,
      //       });
      //     } else {
      //       if (res.data !== undefined) {
      //         let blob = res.data;
      //         blob
      //           .arrayBuffer()
      //           .then(async (buffer) => {
      //             await this.addHexToMap(buffer, centerCoords);
      //             this.setState({
      //               hexDone: true,
      //             });
      //             // console.log('Hexgrid DONE');
      //           })
      //           .catch((err) => {
      //             this.setState({
      //               hexDone: true,
      //             });
      //             console.log(err);
      //             this.props.sendErrorNotification(err);
      //           });
      //       } else {
      //         this.setState({
      //           hexDone: true,
      //         });
      //       }
      //     }
      //   });
      // }

      this.setState({
        showLoadingModal: false,
      });
      // console.log(mosaicBounds)
      if (mosaicBounds !== undefined) {
        this.myMap.fitBounds(mosaicBounds);
      }
    } catch (error) {
      console.log(error);
      //console.log("Catch")
    }
  };

  addHomeAndZoom = (map, centerCoords, zoom) => {
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: "topleft",
        zoomInText: "+",
        zoomInTitle: "Zoom in",
        zoomOutText: "-",
        zoomOutTitle: "Zoom out",
        zoomHomeText: '<i class="fa fa-home" style="line-height:1.65;"></i>',
        zoomHomeTitle: "Zoom home",
      },

      onAdd: function (map) {
        var controlName = "gin-control-zoom",
          container = L.DomUtil.create("div", controlName + " leaflet-bar"),
          options = this.options;

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + "-in",
          container,
          this._zoomIn
        );
        this._zoomHomeButton = this._createButton(
          options.zoomHomeText,
          options.zoomHomeTitle,
          controlName + "-home",
          container,
          this._zoomHome
        );
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + "-out",
          container,
          this._zoomOut
        );

        this._updateDisabled();
        map.on("zoomend zoomlevelschange", this._updateDisabled, this);

        return container;
      },

      onRemove: function (map) {
        map.off("zoomend zoomlevelschange", this._updateDisabled, this);
      },

      _zoomIn: function (e) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _zoomHome: function (e) {
        if (centerCoords !== undefined) {
          map.setView(centerCoords, zoom);
        }
      },

      _createButton: function (html, title, className, container, fn) {
        var link = L.DomUtil.create("a", className, container);
        link.innerHTML = html;
        link.href = "#";
        link.title = title;

        L.DomEvent.on(link, "mousedown dblclick", L.DomEvent.stopPropagation)
          .on(link, "click", L.DomEvent.stop)
          .on(link, "click", fn, this)
          .on(link, "click", this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = "leaflet-disabled";

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });

    let zoomHome = new L.Control.zoomHome();
    zoomHome.addTo(map);
  };

  addTiffToMap = async (bounds, getState) => {
    // let tileCoords = [];
    //Function to extend Gridlayer
    //https://leafletjs.com/reference-1.7.1.html#gridlayer
    //Makes it possible to use django backend as URL generator
    L.GridLayer.GetTileCoords = L.GridLayer.extend({
      getTileUrl: async function (coords) {
        return await tileFunction(globalOrderID, coords.z, coords.x, coords.y);
      },
      createTile: function (coords) {
        var tile = document.createElement("canvas");
        this.getTileUrl(coords).then((res) => {
          var url = res;
          if (url !== "") {
            var tileSize = this.getTileSize();
            tile.setAttribute("width", tileSize.x);
            tile.setAttribute("height", tileSize.y);

            let context = tile.getContext("2d");

            let img = new Image();
            img.onload = function () {
              context.drawImage(img, 0, 0);
            };
            img.src = url;
          }
        });
        //Sets TileSize to correct tilesize, looks awful without it

        return tile;
      },
      options: {
        tms: false,
        errorTileUrl:
          "https://upload.wikimedia.org/wikipedia/commons/d/d8/Friedrich-Johann-Justin-Bertuch_Mythical-Creature-Dragon_1806.jpg",
        opacity: 1,
        bounds: bounds,
      },
    });

    //Function that calls the extension function \o/
    L.gridLayer.getTileCoords = function (opts) {
      return new L.GridLayer.GetTileCoords(opts);
    };
    let token = tokenConfig(getState).headers["Authorization"];
    token = token.split(" ")[1];
    var mosaicLayer = L.tileLayer(
      `${ip}/api/v1/tile/${globalOrderID}/{z}/{x}/{y}/?token=${token}`,
      {
        maxZoom: 20,
      }
    ).addTo(this.myMap);

    //Varaible that contains the overlay layer when rendered
    // var mosaicLayer = L.gridLayer.getTileCoords().addTo(this.myMap);

    //Adds mosaic to overlay control
    if (!this.state.overlayExists) {
      this.setState({ overlayExists: true }, function () {
        overlays["Mosaic"] = mosaicLayer;
        overlayControl = L.control.layers(null, overlays).addTo(this.myMap);
      });
    } else {
      overlayControl.remove(this.myMap);
      overlays["Mosaic"] = mosaicLayer;
      overlayControl = L.control.layers(null, overlays).addTo(this.myMap);
    }
  };

  addSHPToMap = async (zipfile, name, centerCoords) => {
    var geo = L.geoJson(
      { features: [] },
      {
        onEachFeature: function popUp(f, l) {
          l.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToFeature,
          });
        },
        style: function style(feature) {
          if (class_id(feature.properties) === 6) {
            if ("certainty" in feature.properties) {
              return {
                fillColor: stressedColors[feature.properties.stressed_c],
                weight: 0,
                fillOpacity: 0.85,
              };
            } else {
              return {
                fillColor: legendColors[class_id(feature.properties)],
                weight: 0,
                fillOpacity: 0.85,
              };
            }
          } else {
            return {
              fillColor: legendColors[class_id(feature.properties)],
              weight: 0,
              fillOpacity: 0.85,
            };
          }
        },
      }
    ).addTo(this.myMap);

    shp(zipfile)
      .then((data) => {
        switch (data.fileName) {
          case "ground":
          case "mosaic_ground":
            geo.addData(data);
            geo.remove();
            break;
          default:
            // console.log(data)
            geo.addData(data);
        }
        // var text = "<b style='font-size: 130%'; >data.fileName</b>"
        if (!this.state.overlayExists) {
          this.setState({ overlayExists: true }, function () {
            overlays[name] = geo;
            overlayControl = L.control.layers(null, overlays).addTo(this.myMap);
          });
        } else {
          overlayControl.remove(this.myMap);
          overlays[name] = geo;
          overlayControl = L.control.layers(null, overlays).addTo(this.myMap);
        }
        if (centerCoords === undefined) {
          // console.log(geo.getBounds())
          this.myMap.fitBounds(geo.getBounds());
        }
      })
      .catch((err) => {
        console.log("Could not read the shapefile");
        console.log(err);
      });

    if (!this.state.infoExists) {
      info = L.control();
    }

    const highlightFeature = (e) => {
      var layer = e.target;

      layer.setStyle({
        weight: 3,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.7,
      });

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
      // console.log(layer.feature.properties.stressed_c)
      info.update(layer.feature);
    };

    const resetHighlight = (e) => {
      geo.resetStyle(e.target);
      info.update();
    };

    const zoomToFeature = (e) => {
      this.myMap.fitBounds(e.target.getBounds());
    };

    info.onAdd = function (map) {
      this._div = L.DomUtil.create("div", css.info); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    info.update = function (props) {
      if (props) {
        let properties = props.properties;
        let polygon = props.geometry.coordinates;
        polygon = turf.polygon(polygon);
        var area = Math.round(turf.area(polygon)) * 0.0001;

        // console.log(properties)

        if (!("stressed_c" in properties) || properties.stressed_c === "") {
          this._div.innerHTML =
            "<h4>Tree type</h4>" +
            "<b>" +
            legendNames[class_id(properties)] +
            "</b><br />" +
            area.toFixed(3) +
            " hectars <br />";
        } else {
          this._div.innerHTML =
            "<h4>Tree type</h4>" +
            "<b>" +
            stressedNames[properties.stressed_c] +
            "</b><br />" +
            area.toFixed(3) +
            " hectars <br />";
        }
      } else {
        this._div.innerHTML = "<h4>Tree type</h4> Hover over an area";
      }
    };

    if (!this.state.infoExists) {
      this.setState({ infoExists: true }, function () {
        info.addTo(this.myMap);
      });
    }

    // Add legend
    if (!this.state.legendExists) {
      legend = L.control({ position: "bottomright" });
    }

    legend.onAdd = function (map) {
      var div = L.DomUtil.create("div", css.legend),
        grades = [1, 2, 3, 4, 5, 6, 7];
      // labels = [];

      // loop through our density intervals and generate a label with a colored square for each interval
      for (var i = 0; i < grades.length; i++) {
        if (i === 5) {
          div.innerHTML +=
            '<i style="background:' +
            stressedColors["High_certain"] +
            '"></i> ' +
            stressedNames["High_certain"] +
            " <br>";
          div.innerHTML +=
            '<i style="background:' +
            stressedColors["Low_certain"] +
            '"></i> ' +
            stressedNames["Low_certain"] +
            " <br>";
        } else {
          // console.log(i, legendNames[grades[i]])
          div.innerHTML +=
            '<i style="background:' +
            legendColors[grades[i]] +
            '"></i> ' +
            legendNames[grades[i]] +
            " <br>";
        }
      }

      return div;
    };

    if (!this.state.legendExists) {
      this.setState({ legendExists: true }, function () {
        legend.addTo(this.myMap);
      });
    }
  };

  addHexToMap = async (zipfile, centerCoords) => {
    let min = 0;
    let max = 20;
    let hexGridLayer;

    var geo = L.geoJson({ features: [] });

    shp(zipfile)
      .then((data) => {
        geo.addData(data);
        let featureList = Object.keys(geo["_layers"]).map((key) => {
          let hex = geo["_layers"][key]["feature"];
          let count = hex["properties"]["count"];
          hex["properties"]["showOnMap"] = true;
          if (count === 0) {
            hex["properties"]["showOnMap"] = false;
          }
          // if (count < min ) {
          //   min = count;
          // } else if (count > max) {
          //   max = count;
          // };
          return hex;
        });
        let featureCollection = turf.featureCollection(featureList);
        let [minlat, minlng, maxlat, maxlng] = turf.bbox(featureCollection);
        this.myMap.fitBounds([
          [minlng, minlat],
          [maxlng, maxlat],
        ]);
        addBufferToMap(this.myMap, featureCollection);
        hexGridLayer.remove();
        if (!this.state.overlayExists) {
          this.setState({ overlayExists: true }, function () {
            overlays["HexGrid"] = hexGridLayer;
            overlayControl = L.control.layers(null, overlays).addTo(this.myMap);
          });
        } else {
          overlayControl.remove(this.myMap);
          overlays["HexGrid"] = hexGridLayer;
          overlayControl = L.control.layers(null, overlays).addTo(this.myMap);
        }
        // if ( centerCoords === undefined ) {
        //   console.log(geo.getBounds())
        //   this.myMap.fitBounds(geo.getBounds())
        // };
      })
      .catch((err) => {
        console.log("Could not read the shapefile");
        console.log(err);
      });

    const percentColors = [
      { pct: 0.0, color: { r: 0xff, g: 0xff, b: 0xff } },
      { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0x00 } },
    ];

    const getColorForPercentage = function (pct) {
      for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
          break;
        }
      }
      var lower = percentColors[i - 1];
      var upper = percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
      };
      return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
    };

    const addBufferToMap = (map, hexGrid) => {
      hexGridLayer = L.geoJSON(hexGrid, {
        onEachFeature: function popUp(f, l) {
          l.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToFeature,
          });
        },
        style: function (feature) {
          let percent = feature.properties.count / (max - min);
          let fillColor = getColorForPercentage(percent);
          return {
            fillColor: fillColor,
            fillOpacity: 0.5,
            color: "#000",
          };
        },
        filter: function (feature) {
          return feature.properties.showOnMap;
        },
      }).addTo(map);
    };

    if (!this.state.infoExists) {
      info = L.control();
    }

    const highlightFeature = (e) => {
      var layer = e.target;

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
      info.update(layer.feature);
    };

    const resetHighlight = (e) => {
      // geo.resetStyle(e.target);
      info.update();
    };

    const zoomToFeature = (e) => {
      this.myMap.fitBounds(e.target.getBounds());
    };

    info.onAdd = function (map) {
      this._div = L.DomUtil.create("div", css.info); // create a div with a class "info"
      this.update();
      return this._div;
    };

    // method that we will use to update the control based on feature properties passed
    info.update = function (props) {
      if (props) {
        let properties = props.properties;
        let count = properties.count;
        if (count) {
          this._div.innerHTML = "<h4>Number of trees</h4>";
          this._div.innerHTML = this._div.innerHTML + count + " trees <br />";
        } else {
          let polygon = props.geometry.coordinates;
          polygon = turf.polygon(polygon);
          let area = Math.round(turf.area(polygon)) * 0.0001;
          this._div.innerHTML =
            "<h4>Tree type</h4>" +
            "<b>" +
            legendNames[class_id(properties)] +
            "</b><br />" +
            area.toFixed(3) +
            " hectars <br />";
        }
      } else {
        this._div.innerHTML = "<h4>Hover over an area</h4>";
      }
    };

    if (!this.state.infoExists) {
      this.setState({ infoExists: true }, function () {
        info.addTo(this.myMap);
      });
    }
  };

  render() {
    return (
      <>
        <div style={{ zIndex: "2" }}>{this.props.children}</div>
        <MapWrapper
          showLoadingModal={this.state.showLoadingModal}
          mapRef={this.mapRef}
        />
      </>
    );
  }
}

function MapWrapper(props) {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/leaflet.locatecontrol/dist/L.Control.Locate.min.css"
      />
      <Fragment>
        <div className={css.webmap} ref={props.mapRef}></div>
        {props.showLoadingModal ? (
          <div className={css.loadingModalWrapper} style={{ zIndex: "3" }}>
            <div className={css.loadingModal}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading analysis, please wait ...
            </div>
          </div>
        ) : (
          " "
        )}
      </Fragment>
    </>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getOrder,
  sendNotification,
  sendErrorNotification,
  getZipFileLeafleet,
  getMosaicLinkLeaflet,
  getTilesetLink,
  getTreeCountLinkLeaflet,
  tokenConfig,
})(LeafletMapResults);
