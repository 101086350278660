import React, { Component, Fragment, useState } from "react";
import L from "leaflet";
import "leaflet-draw";
import "leaflet-control-geocoder";
import { Form, Spinner, Overlay, Tooltip } from "react-bootstrap";
import shpwrite from "@mapbox/shp-write";
// import proj4 from "proj4";
import shp from "shpjs";
// import leafletImage from "leaflet-image";
// import domtoimage from "dom-to-image";
import imageURL from "../../images/myForestPlaceholder.png";
import * as turf from "@turf/turf";
import "leaflet.locatecontrol";

// Store imports
import { connect } from "react-redux";
import {
  sendNotification,
  sendErrorNotification,
} from "../../actions/notifications";
import {
  getForest,
  getZipFileLeafleet,
  clearForests,
} from "../../actions/myforest";

import { addNewOrder } from "../../actions/orders";

import { getZipFileLeafleet as analysisShp } from "../../actions/webmapview";

// Local components imports
// import ForestInfoModal from "../modals/ForestInfoModal";
// import { legendColors, legendNames } from "../../GLOBAR_PARAMETERS";

// css imports
import css from "../../styles/MapSubPage.module.css";

//icons
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
// import * as AiIcons from 'react-icons/ai';
import * as IoIcons from "react-icons/io";
// import axios from "axios";
// import { validateBBox } from "@turf/turf";

//Global variables
// var info;
// var legend;
// var overlays = {};

class LeafletMapChooseExtent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.hiddenFileInputRef = React.createRef();
    this.state = {
      enter_long: 15.593688,
      center_lat: 58.403128,
      shp_number: 0,
      showModal: false,
      forestName: this.props.forestName ? this.props.forestName : "",
      forestId: this.props.forest.id ? this.props.forest.id : "",
      forest_area: 0,
      showLoadingModal: false,
      selectedFile: null,
      uploadButton: "primary",
      infoExists: false,
      legendExists: false,
      overlayExists: false,
      analysisName: this.props.state.analysisName
        ? this.props.state.analysisName
        : "",
      hectars: 0,
      analysisType: this.props.state.analysisType
        ? this.props.state.analysisType
        : "",
      maxRunsExceeded: false,
      forestLeafletPoly: null,
      analysisAreaExists: false,
    };
  }

  addSHPToMap = async (zipfile) => {
    var geo = L.geoJSON(
      { features: [] },
      {
        style: function () {
          return {
            color: "#0B666E",
            weight: 10,
            fillOpacity: 0,
          };
        },
      }
    ).addTo(this.map);

    shp(zipfile)
      .then((data) => {
        this.setState({
          forestLeafletPoly: data,
        });
        geo.addData(data);
        geo.bringToBack();
        this.setState({
          forestLeafletGeo: geo,
        });
        var overlays = {
          shapefile: geo,
        };
        if (!this.state.overlayExists) {
          this.setState({ overlayExists: true }, function () {
            L.control.layers(overlays).addTo(this.map);
          });
        }
        this.map.fitBounds(geo.getBounds());
        this.setState({
          showLoadingModal: false,
        });
      })
      .catch((err) => {
        this.props.sendErrorNotification(
          "There was an error loading the shapefile"
        );
        this.setState({ selectedFile: null }, function () {
          this.setState({ uploadButton: "danger" }, function () {
            console.error("Could not read the shapefile");
            console.error(err);
          });
        });
        return;
      });
    return geo;
  };

  handleShow = () => {
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  b64toBlob = async (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    blob.name = "shapefile.zip";
    return blob;
  };

  componentDidMount() {
    if (this.props.forest) {
      this.props.getForest(this.props.forest.id);
    }
    this.createMap();
  }

  componentWillUnmount() {
    this.props.clearForests();
  }

  //search forest
  leafletControlSearch = () => {
    // Add search bar
    L.Control.geocoder({
      position: "topleft",
    }).addTo(this.map);
  };

  leafletChangeBasemap = (basemap) => {
    //delete tileLayer first
    let baselayers = {
      Satellite: L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
      ),
      Streets: L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
      ),
      Topo: L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
      ),
    };
    baselayers[basemap].addTo(this.map);
  };

  //info pop up after save forest name
  popUpNotification = () => {
    // Generate popup content based on layer type
    // - Returns HTML string, or null if unknown object
    var getPopupContent = function (layer) {
      var latlngs = layer._defaultShape
          ? layer._defaultShape()
          : layer.getLatLngs(),
        area = L.GeometryUtil.geodesicArea(latlngs);
      return L.GeometryUtil.readableArea(area, true);
    };

    // Object created - bind popup to layer, add to feature group
    this.map.on(L.Draw.Event.CREATED, (event) => {
      this.forestPoly = this.state.forestLeafletPoly["features"][0];
      var layer = event.layer;
      let analysisPoly = layer.toGeoJSON();
      // console.log(forestPoly, analysisPoly)
      let intersectionPoly = turf.intersect(this.forestPoly, analysisPoly);
      //   intersection.bringToFront();
      // console.log(intersectionPoly)
      this.leafletIntersection = L.geoJSON(intersectionPoly, {
        style: function (feature) {
          return { color: "#FF0000" };
        },
      }).addTo(this.map);
      // console.log(leafletIntersection)
      // leafletIntersection.bringToFront();
      // console.log(this.state.forestLeafletGeo)
      this.state.forestLeafletGeo.bringToBack();
      this.drawnItems.addLayer(layer);
      this.setState({
        analysisAreaExists: true,
      });
      var area = getPopupContent(layer);
      // console.log(area)
      this.setState({
        hectars: parseFloat(area),
      });
      //   if (content !== null) {
      //     // intersection.bindPopup("Area: " + content);
      //   }
      //   this.drawnItems.addLayer(intersection);
      //   this.props.sendNotification(
      //     `Your forest area is ${this.state.forest_area} hectares`
      //   );
    });

    // Object(s) edited - update popups
    this.map.on(L.Draw.Event.EDITED, (event) => {
      var layers = event.layers;
      // , content = null;
      // let forestPoly = this.state.forestLeafletPoly['features'][0];
      layers.eachLayer((layer) => {
        this.leafletIntersection.clearLayers();
        let analysisPoly = layer.toGeoJSON();
        let intersectionPoly = turf.intersect(this.forestPoly, analysisPoly);
        // this.leafletIntersection = L.geoJSON(
        //   intersectionPoly,
        //     {
        //     style: function (feature) {
        //         return {color: '#FF0000'};
        //     }
        // }).addTo(this.map);
        this.leafletIntersection.addData(intersectionPoly);
        // content = getPopupContent(layer);
        // if (content !== null) {
        //   layer.setPopupContent(content);
        // };
      });
    });
  };

  //delete layer
  leafletDeleteLayer = () => {
    this.map.on(L.Draw.Event.DELETED, (event) => {
      this.leafletIntersection.clearLayers();
      // this.editDrawControl.setDrawingOptions({
      //   edit: {
      //     featureGroup: this.drawnItems,
      //     poly: {
      //       allowIntersection: false,
      //     },
      //     remove: true,
      //   },
      //   draw: false
      // });

      this.setState({
        analysisAreaExists: false,
      });
      // this.drawControl.setDrawingOptions({
      //   polygon: {
      //     allowIntersection: false,
      //     showArea: true,
      //   },
      // });
      this.map.removeControl(this.editDrawControl);
      // this.map.addControl(this.editDrawControl);
    });
  };

  overlayAdded = () => {
    this.map.on("layeradd", (event) => {
      // console.log(event)
    });
  };

  addHomeAndZoom = (map, zoom) => {
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: "topleft",
        zoomInText: "+",
        zoomInTitle: "Zoom in",
        zoomOutText: "-",
        zoomOutTitle: "Zoom out",
        zoomHomeText: '<i class="fa fa-home" style="line-height:1.65;"></i>',
        zoomHomeTitle: "Zoom home",
      },

      onAdd: function (map) {
        var controlName = "gin-control-zoom",
          container = L.DomUtil.create("div", controlName + " leaflet-bar"),
          options = this.options;

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + "-in",
          container,
          this._zoomIn
        );
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + "-out",
          container,
          this._zoomOut
        );

        this._updateDisabled();
        map.on("zoomend zoomlevelschange", this._updateDisabled, this);

        return container;
      },

      onRemove: function (map) {
        map.off("zoomend zoomlevelschange", this._updateDisabled, this);
      },

      _zoomIn: function (e) {
        this._map.zoomIn(e.shiftKey ? 3 : 1);
      },

      _zoomOut: function (e) {
        this._map.zoomOut(e.shiftKey ? 3 : 1);
      },

      _createButton: function (html, title, className, container, fn) {
        var link = L.DomUtil.create("a", className, container);
        link.innerHTML = html;
        link.href = "#";
        link.title = title;

        L.DomEvent.on(link, "mousedown dblclick", L.DomEvent.stopPropagation)
          .on(link, "click", L.DomEvent.stop)
          .on(link, "click", fn, this)
          .on(link, "click", this._refocusOnMap, this);

        return link;
      },

      _updateDisabled: function () {
        var map = this._map,
          className = "leaflet-disabled";

        L.DomUtil.removeClass(this._zoomInButton, className);
        L.DomUtil.removeClass(this._zoomOutButton, className);

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className);
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className);
        }
      },
    });

    let zoomHome = new L.Control.zoomHome();
    zoomHome.addTo(map);
  };

  //init basemap
  createMap = () => {
    this.map = new L.Map(this.mapRef.current, {
      center: new L.LatLng(58.403128, 15.593688),
      zoom: 15,
      zoomControl: false,
      tap: false,
    });
    this.drawnItems = L.featureGroup().addTo(this.map);
    // this.drawnItems.setStyle()
    this.leafletControlSearch();
    this.leafletChangeBasemap("Satellite");

    L.control
      .scale({
        maxWidth: 250,
      })
      .addTo(this.map);

    this.addHomeAndZoom(this.map, this.map._zoom);

    L.control
      .locate({
        flyTo: true,
        returnToPrevBounds: true,
        keepCurrentZoomLevel: true,
        locateOptions: {
          enableHighAccuracy: true,
        },
      })
      .addTo(this.map);

    // Get the shapefile for existing forest if there is any
    if (this.props.forest) {
      this.props.getZipFileLeafleet(this.props.forest, this.addSHPToMap);
    } else {
      this.setState({
        showLoadingModal: false,
      });
    }
    this.popUpNotification();

    this.leafletDeleteLayer();
  };

  //----------end of createmap()-------------------

  errorHandler = (error) => {
    console.log("ERROR UPLOADING");
    console.log(error);
  };

  addShapefileToMap = (zipfile) => {
    //var geo = L.geoJson().addTo(this.map);

    shp(zipfile)
      .then((data) => {
        // For some reason the coords are flipped, so flip them back
        var coords = data.features[0].geometry.coordinates[0].map(
          (value, index, array) => {
            // console.log(value);
            return [value[1], value[0]];
          }
        );
        L.polygon(coords, { color: "blue" }).addTo(this.drawnItems);

        this.map.fitBounds(this.drawnItems.getBounds());
        this.drawControl.setDrawingOptions({
          polygon: false,
        });
        this.map.removeControl(this.drawControl);
        this.map.addControl(this.drawControl);
        this.setState({
          showLoadingModal: false,
        });
      })
      .catch((err) => {
        console.log("Could not read the shapefile");
        console.log(err);
      });
  };

  zoomToLayer = () => {
    if (this.props.point_type === "shape") {
      this.view.goTo(this.sourceGraphics).catch(function (error) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      });
    } else {
      this.view
        .goTo({
          center: [this.state.center_long, this.state.center_lat],
        })
        .then(() => {
          this.setState({
            showLoadingModal: false,
          });
        })
        .catch(function (error) {
          if (error.name !== "AbortError") {
            console.error(error);
          }
        });
    }
  };

  // roundNumber = (number) => {
  //   return Math.round(number * 100) / 100;
  // };

  onCreateOrder = async (exceed) => {
    let shapeType = "";
    let analysisShape = null;
    if (this.state.analysisAreaExists === false) {
      shapeType = "Forest";
    } else {
      await this.saveShapefile().then((shapefile) => {
        this.b64toBlob(shapefile, "application/x-zip-compressed").then(
          (shp_blob) => {
            analysisShape = shp_blob;
          }
        );
      });
      shapeType = "Subsection";
    }

    // console.log(this.state)
    // console.log(this.props)
    return await this.props
      .addNewOrder(
        this.state.forestId,
        this.state.analysisName,
        0,
        this.props.state.analysisType,
        exceed,
        shapeType,
        analysisShape
      )
      .then((res) => {
        if (res.status === 201) {
          this.props.createMessage({
            customSuccess: "Order has been created!",
          });
          return true;
        } else if (res.status === 402) {
          this.props.sendErrorNotification(res.data);
          this.setState({
            maxRunsExceeded: true,
          });
          return false;
        } else {
          setTimeout(function () {
            this.props.sendErrorNotification(res.data);
          }, 3000);
          return false;
        }
      });
  };

  saveShapefile = async () => {
    // (optional) set names for feature types and zipped folder
    var options = {
      folder: "shapefile",
      types: {
        polygon: "shapefile",
      },
    };

    // a GeoJSON bridge for features

    // The sketched geometry of the polygon are for some reason in EPSG:3857 format and
    // need to be converted to WG84
    // var epsg =
    //   "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs";
    // var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
    var coords = [];
    // for (const [, value] of Object.entries(this.drawnItems._layers)) {
    //   value.getLatLngs()[0].forEach((element) => {
    //     coords.push(element);
    //   });
    // };

    for (const [, value] of Object.entries(this.leafletIntersection._layers)) {
      value.getLatLngs()[0].forEach((element) => {
        coords.push(element);
      });
    }
    const longLat = coords.map((coords) => {
      //return proj4(epsg, wgs84, [coords["y"], coords["x"]]);
      return [coords["lng"], coords["lat"]];
    });

    const shapefile = shpwrite.zip(
      {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [...longLat],
            },
            properties: {
              name: "myForest",
            },
          },
        ],
      },
      options
    );
    return shapefile;
  };

  getScreenshot = async () => {
    // Read this: https://dev.to/gabiaxel/exporting-leaflet-map-to-image-in-the-browser-16am

    let blob = await fetch(imageURL).then((r) => r.blob());
    return blob;
  };

  enableDrawPolygon = () => {
    this.createDrawControl = new L.Control.Draw({
      draw: {
        polygon: {
          allowIntersection: false,
          showArea: true,
        },
        rectangle: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        marker: false,
      },
    });

    this.editDrawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        poly: {
          allowIntersection: false,
        },
        remove: true,
      },
      draw: false,
    });

    new L.Draw.Polygon(
      this.map,
      this.createDrawControl.options.polygon
    ).enable();
    this.map.addControl(this.editDrawControl);
  };

  saveForestName = (name) => {
    this.setState({
      forestName: name,
    });
  };

  render() {
    return (
      <div className={css.mainFrame}>
        <MapSettingSidenavRight
          {...this.props}
          onCreateOrder={this.onCreateOrder}
          enableDrawPolygon={this.enableDrawPolygon}
          analysisAreaExists={this.state.analysisAreaExists}
          maxRunsExceeded={this.state.maxRunsExceeded}
          userData={this.props.state.userData}
          orderType={this.props.state.analysisType}
          hectars={this.state.hectars}
          forest={this.props.state.forest}
          goBack={this.props.goBack}
        />

        <NavOnMap
          {...this.props}
          handleShow={this.handleShow}
          saveForestName={this.saveForestName}
          leafletChangeBasemap={this.leafletChangeBasemap}
        />

        <MapWrapper
          mapRef={this.mapRef}
          className={css.mapWrapper}
          showLoadingModal={this.state.showLoadingModal}
        />
      </div>
    );
  }
}

function MapWrapper(props) {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/leaflet.locatecontrol/dist/L.Control.Locate.min.css"
      />
      <Fragment>
        <div className={css.webmap} ref={props.mapRef} />
        {props.showLoadingModal ? (
          <div className={css.loadingModalWrapper}>
            <div className={css.loadingModal}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading the shape, please wait ...
            </div>
          </div>
        ) : (
          <div className={css.loadingModalWrapperExit}>
            <div className={css.loadingModalExit}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading the shape, please wait ...
            </div>
          </div>
        )}
      </Fragment>
    </>
  );
}

//Map Sidenav with settings
const MapSettingSidenavRight = ({
  onCreateOrder,
  enableDrawPolygon,
  analysisAreaExists,
  maxRunsExceeded,
  userData,
  orderType,
  hectars,
  forest,
  goBack,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const settingButtons = {
    arrowOpen: <FaIcons.FaChevronRight />,
    arrowClose: <FaIcons.FaChevronLeft />,
  };

  const [exceed, setExceed] = useState(false);
  const orderHectars = hectars === 0 ? forest.size : hectars;

  const handleExceed = () => {
    setExceed(!exceed);
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const drawPolygon = () => {
    toggleSidebar();
    enableDrawPolygon();
  };

  const createOrder = (e) => {
    onCreateOrder(e).then((res) => {
      console.log(res);
      if (res) {
        goBack();
      } else {
        setExceed(false);
      }
    });
  };

  const fixHectars = (hectars, forest) => {
    if (hectars === 0) {
      return (
        <>
          <p>{`Your order is`}</p>
          <h4>
            {`${forest.size}`}
            <span>{`hectars`} </span>
          </h4>
        </>
      );
    } else {
      return (
        <>
          <p>{`Your order is`}</p>
          <h4>
            {`${hectars}`}
            <span>{`hectars`} </span>
          </h4>
        </>
      );
    }
  };

  const checkPrice = (userType, orderType, maxExceeded) => {
    // console.log('User type', (userType));
    // console.log('Order Type', (orderType));
    // console.log(maxExceeded)
    let pricePerHektar = 10;
    let price = "";
    let error = "";
    let diff = orderType - userType;
    if (diff > 0 || maxExceeded) {
      let userBinary = userType.toString(2);
      let orderBinary = orderType.toString(2);
      while (userBinary.length < orderBinary.length) {
        // console.log('Fixing length', userBinary)
        userBinary = "0" + userBinary;
      }
      // console.log('User', userBinary)
      // console.log('Order', orderBinary)
      let numOfDiffTypes = 0;
      for (const index in userBinary) {
        let userChar = userBinary[index];
        let orderChar = orderBinary[index];
        if (maxExceeded) {
          if (orderChar === "1") {
            numOfDiffTypes += 1;
          }
        } else if (userChar !== orderChar && userChar !== "1") {
          numOfDiffTypes += 1;
        }
      }
      // console.log('Num of differences between userType and orderType', numOfDiffTypes);
      let orderPricePerHektar = pricePerHektar * numOfDiffTypes;
      let orderPrice = orderPricePerHektar * orderHectars;
      if (maxExceeded) {
        error = (
          <>
            <h5 style={{ color: "red" }}>Max number of runs exceeded</h5>
            <p>
              <b>
                Uploading this order will exceed the maximum alloted orders in
                your subscription and you will be debitted {pricePerHektar}{" "}
                kr/ha per type of order you choose
              </b>
            </p>
          </>
        );
      } else if (diff > 0) {
        error = (
          <>
            <h5 style={{ color: "red" }}>
              Scope of order falls outside of usertype
            </h5>
            <p>
              <b>
                Since soem the order types fall outside of the scope of your
                subscription with us you will be debitted {pricePerHektar} kr/ha
                per type of order you choose
              </b>
            </p>
          </>
        );
      }
      if (error !== "") {
        price = (
          <>
            {error}
            <p>
              Based on your selection the price will be {orderPrice} kr and
              debitted after the order has completed.
            </p>
            <br></br>
            <label>
              <input type="checkbox" checked={exceed} onChange={handleExceed} />
              I agree to being charged extra for this orde r
            </label>
          </>
        );
      }
    }
    return price;
  };

  //   const shpUploadClicked = () => {
  //     hiddenFileInputRef.current.click();
  //   };

  //   const fileUploaded = (e) => {
  //     toggleSidebar();
  //     onFileChange(e);
  //   }

  //Makes the string go from test_mc_test to Test Mc Test

  return (
    <>
      <div
        className={css.contentRight}
        style={{ right: isOpened ? "0" : "-300px" }}
        backdrop="static"
        keyboard="false"
      >
        <div className={css.buttonRight} onClick={toggleSidebar}>
          {isOpened ? settingButtons.arrowOpen : settingButtons.arrowClose}
        </div>

        {/* tools in sidebar */}

        <div className={css.tools}>
          <h5>Order Information</h5>
          <hr
            style={{
              margin: "0 0 8px 0",
              borderBottom: "0.5px solid rgba(231, 231, 231, 0.4)",
              opacity: 0.5,
            }}
          />
          {fixHectars(hectars, forest)}
          {userData !== null
            ? !userData.is_premium
              ? checkPrice(userData.user_type, orderType, maxRunsExceeded)
              : ""
            : ""}
        </div>

        <div className={css.tools}>
          <h5>Tools</h5>
          <hr
            style={{
              margin: "0 0 8px 0",
              borderBottom: "0.5px solid rgba(231, 231, 231, 0.4)",
              opacity: 0.5,
            }}
          />
          <div className={css.toolsGroup}>
            {/* draw shape button */}
            {analysisAreaExists ? (
              ""
            ) : (
              <div className={css.toolContainer} onClick={drawPolygon}>
                <BiIcons.BiShapeTriangle className={css.icons} />
                <h6>Draw Shape</h6>
              </div>
            )}

            {exceed ? (
              <>
                {maxRunsExceeded ? (
                  <div
                    className={css.toolContainer}
                    onClick={() => createOrder(true)}
                    style={{ marginTop: "5px" }}
                  >
                    <BiIcons.BiSave className={css.icons} />
                    <h6>Order Analysis</h6>
                  </div>
                ) : (
                  <div
                    className={css.toolContainer}
                    onClick={() => createOrder(false)}
                    style={{ marginTop: "5px" }}
                  >
                    <BiIcons.BiSave className={css.icons} />
                    <h6>Order Analysis</h6>
                  </div>
                )}
              </>
            ) : (
              <div
                className={css.toolContainer}
                onClick={() => createOrder(false)}
                style={{ marginTop: "5px" }}
              >
                <BiIcons.BiSave className={css.icons} />
                <h6>Order Analysis</h6>
              </div>
            )}
          </div>
        </div>

        {/* forest information: displayed after map is shaped */}
        {/* OLD */}
        {/* <div className={css.tools}>
            <h5>Forest Info</h5>
            <hr 
            style={{
            margin: '0 0 5px 0',
            borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)', 
            opacity: 0.5}}/> 
            <p>{`Your forest is`}</p>
            <h4>{`${forestStats.size}`}<span>{`hectars`} </span></h4>
            
            <p>{`The cost of analysis is`}</p>
            <h4>{`${Math.round((forestStats.size*pricePerHectar + Number.EPSILON) * 100) / 100}`}<span>{`kr`}</span></h4>

              {/* Hmm sus  */}
        {/* <p>{`The cost of analysis with a drone pilot is`}</p>
            <h4>{`${Math.round(((forestStats.size*80+1000) + Number.EPSILON) * 100) / 100}`}<span>{`kr`}</span></h4>
          </div> */}
      </div>

      {isOpened ? (
        <div className={css.overlayRight} onClick={toggleSidebar} />
      ) : (
        ""
      )}
    </>
  );
};

//NEW SUB COMPONENTS
class NavOnMap extends Component {
  constructor(props) {
    super(props);
    this.forestNameRef = React.createRef();
    this.state = {
      matches: window.matchMedia("(min-width: 768px)").matches,
      showMapType: false,
      forestName: "",
      isShowingForestNameTooltip: false,
    };
  }

  toggleShowMapType = () => {
    this.setState({
      showMapType: !this.state.showMapType,
    });
  };

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  onForestNameChanged = (event) => {
    if (event.target.value === "") {
      this.hideForestNameTooltip();
    } else if (!event.target.value.match(/^[A-Za-z0-9äöå\s]+$/i)) {
      this.showForestNameTooltip();
      // this.setState({
      //   forestName: "",
      // });
      this.props.saveForestName("");
      return;
    } else {
      this.hideForestNameTooltip();
    }
    this.props.saveForestName(event.target.value);
    // this.setState({
    //   forestName: event.target.value,
    // });
  };
  showForestNameTooltip = () => {
    this.setState({
      isShowingForestNameTooltip: true,
    });
  };

  hideForestNameTooltip = () => {
    this.setState({
      isShowingForestNameTooltip: false,
    });
  };

  render() {
    // const { toggleSidebar } = this.props;

    return (
      <>
        <div className={css.navOnMap}>
          {/* buttons below navbar */}

          <div className={css.nameForest}>
            <div className={css.backButton} onClick={this.props.goBack}>
              <i
                className="fas fa-chevron-left"
                style={{ paddingRight: "0.5rem" }}
              ></i>
            </div>

            {/* forest name input */}
            <Form.Control
              ref={this.forestNameRef}
              type="text"
              // placeholder="Name of your forest..."
              aria-label="Name of your forest..."
              placeholder={
                this.props.forestName
                  ? this.props.forestName
                  : "Name of your forest"
              }
              aria-describedby="basic-addon2"
              onChange={this.onForestNameChanged}
              // onClick={toggleSidebar}
            />
            <Overlay
              target={this.forestNameRef.current}
              show={this.state.isShowingForestNameTooltip}
              placement="right"
            >
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Only letters and numbers
                </Tooltip>
              )}
            </Overlay>

            {/* save button */}
            {/* <div 
              className={css.saveIcon}
              onClick={this.props.onSaveForest}>
              <i class="far fa-save"></i>
            </div> */}
          </div>

          {/* different map types */}
          <div onClick={this.toggleShowMapType} className={css.mapType}>
            {
              <div className={css.mapTypeClose}>
                <BiIcons.BiMapAlt />
              </div>
            }
          </div>

          {this.state.showMapType ? (
            <div className={css.mapType}>
              <div className={css.mapTypeOpen}>
                <div
                  className={css.mapHeader}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "fixed",
                  }}
                >
                  <h4
                    style={{
                      paddingRight: "50px",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <strong>Map Type</strong>
                  </h4>
                  <IoIcons.IoIosCloseCircle
                    style={{
                      height: "25px",
                      width: "25px",
                      display: "block",
                    }}
                    className={css.closeIcon}
                    onClick={this.toggleShowMapType}
                  />
                </div>

                <div
                  className={css.mapsContainer}
                  style={{ textAlign: "center" }}
                >
                  <div className={css.mapGroup}>
                    <div
                      className={css.map}
                      onClick={() =>
                        this.props.leafletChangeBasemap("Satellite")
                      }
                    >
                      <div className={css.icon}>
                        <i className="fas fa-satellite fa-3x icon" />
                      </div>
                    </div>
                    <h6>Satellite</h6>
                  </div>

                  <div className={css.mapGroup}>
                    <div
                      className={css.map}
                      onClick={() => this.props.leafletChangeBasemap("Streets")}
                    >
                      <div className={css.icon}>
                        <i className="fas fa-road fa-3x icon"></i>
                      </div>
                    </div>
                    <h6>Street</h6>
                  </div>

                  <div className={css.mapGroup}>
                    <div
                      className={css.map}
                      onClick={() => this.props.leafletChangeBasemap("Topo")}
                    >
                      <div className={css.icon}>
                        <i className="fas fa-mountain fa-3x icon" />
                      </div>
                    </div>
                    <h6>Topo</h6>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user,
});

export default connect(mapStateToProps, {
  sendNotification,
  sendErrorNotification,
  analysisShp,
  addNewOrder,
  getForest,
  getZipFileLeafleet,
  clearForests,
})(LeafletMapChooseExtent);
