import L from 'leaflet'
import 'leaflet-control-geocoder'
import 'leaflet-draw'
import React, { Component, Fragment, useState } from 'react'
import { Form, Overlay, Spinner, Tooltip } from 'react-bootstrap'
import shpwrite from '@mapbox/shp-write'
// import proj4 from "proj4";
import shp from 'shpjs'
// import leafletImage from "leaflet-image";
// import domtoimage from "dom-to-image";
import * as turf from '@turf/turf'
import 'leaflet.locatecontrol'
import imageURL from '../../images/myForestPlaceholder.png'

// Store imports
import { connect } from 'react-redux'
import {
  addForest,
  clearForests,
  getForestAnalyses,
  getForestStats,
  getZipFileLeafleet,
} from '../../actions/myforest'
import {
  sendErrorNotification,
  sendNotification,
} from '../../actions/notifications'

import { getZipFileLeafleet as analysisShp } from '../../actions/webmapview'

// Local components imports
import { legendColors, legendNames } from '../../GLOBAR_PARAMETERS'
import ForestInfoModal from '../modals/ForestInfoModal'

// css imports
import css from '../../styles/MapSubPage.module.css'

//icons
import * as AiIcons from 'react-icons/ai'
import * as BiIcons from 'react-icons/bi'
import * as FaIcons from 'react-icons/fa'
import * as IoIcons from 'react-icons/io'

//Global variables
var info
var legend
// var overlays = {};

function convertUTCDateToLocalDate(date) {
  var dateLocal = new Date(date)
  var newDate = new Date(
    dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
  )
  return newDate.toISOString()
}

class LeafletMapMyForest extends Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
    this.hiddenFileInputRef = React.createRef()
    this.state = {
      enter_long: 15.593688,
      center_lat: 58.403128,
      shp_number: 0,
      showModal: false,
      forest_name: this.props.forestName ? this.props.forestName : '',
      forest_area: 0,
      showLoadingModal: false,
      selectedFile: null,
      uploadButton: 'primary',
      infoExists: false,
      legendExists: false,
      overlayExists: false,
    }
  }

  onFileChange = (event) => {
    var shapefile = event.target.files[0]
    console.log(shapefile.type)
    if (
      shapefile.type !== 'application/x-zip-compressed' &&
      shapefile.type !== 'application/zip'
    ) {
      this.setState({ selectedFile: null }, function () {
        this.setState({ uploadButton: 'danger' }, function () {
          console.error('Wrong file type')
        })
      })
    } else {
      // Update the state
      this.setState({ selectedFile: shapefile }, function () {
        this.setState({ uploadButton: 'success' }, function () {
          var blob = shapefile
          blob.arrayBuffer().then((buffer) => {
            this.addSHPToMap(buffer)
          })
          // this.addSHPToMap(shapefile)
        })
      })
    }
  }

  addSHPToMap = async (zipfile) => {
    console.log('Helo there')
    var geo = L.geoJson(
      { features: [] },
      {
        onEachFeature: function popUp(f, l) {
          l.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToFeature,
          })
        },
        style: function style(feature) {
          return {
            fillColor: legendColors[feature.properties.ID],
            weight: 0,
            fillOpacity: 0.85,
          }
        },
      }
    ).addTo(this.map)

    shp(zipfile)
      .then((data) => {
        geo.addData(data)
        var overlays = {
          shapefile: geo,
        }
        if (!this.state.overlayExists) {
          this.setState({ overlayExists: true }, function () {
            L.control.layers(overlays).addTo(this.map)
          })
        }
        this.map.fitBounds(geo.getBounds())
        this.setState({
          showLoadingModal: false,
        })
      })
      .catch((err) => {
        this.props.sendErrorNotification(
          'There was an error loading the shapefile'
        )
        this.setState({ selectedFile: null }, function () {
          this.setState({ uploadButton: 'danger' }, function () {
            console.error('Could not read the shapefile')
            console.error(err)
          })
        })
        return
      })

    if (!this.state.infoExists) {
      info = L.control()
    }

    const highlightFeature = (e) => {
      var layer = e.target

      layer.setStyle({
        weight: 3,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7,
      })

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront()
      }
      info.update(layer.feature)
    }

    const resetHighlight = (e) => {
      geo.resetStyle(e.target)
      info.update()
    }

    const zoomToFeature = (e) => {
      this.map.fitBounds(e.target.getBounds())
    }

    info.onAdd = function (map) {
      this._div = L.DomUtil.create('div', css.info) // create a div with a class "info"
      this.update()
      return this._div
    }

    // method that we will use to update the control based on feature properties passed
    info.update = function (props) {
      if (props) {
        let properties = props.properties
        let polygon = props.geometry.coordinates
        polygon = turf.polygon(polygon)
        var area = Math.round(turf.area(polygon)) * 0.0001
        this._div.innerHTML =
          '<h4>Tree type</h4>' +
          '<b>' +
          legendNames[properties.ID] +
          '</b><br />' +
          area.toFixed(3) +
          ' hectars <br />'
      } else {
        this._div.innerHTML = '<h4>Tree type</h4> Hover over an area'
      }
    }

    if (!this.state.infoExists) {
      this.setState({ infoExists: true }, function () {
        info.addTo(this.map)
      })
    }

    // Add legend if none exists
    if (!this.state.legendExists) {
      legend = L.control({ position: 'bottomright' })
    }

    legend.onAdd = function (map) {
      var div = L.DomUtil.create('div', css.legend),
        grades = [1, 2, 3, 4, 5, 6]
      // labels = [];

      // loop through our density intervals and generate a label with a colored square for each interval
      for (var i = 0; i < grades.length; i++) {
        div.innerHTML +=
          '<i style="background:' +
          legendColors[grades[i]] +
          '"></i> ' +
          legendNames[grades[i]] +
          ' <br>'
      }
      return div
    }

    if (!this.state.legendExists) {
      this.setState({ legendExists: true }, function () {
        legend.addTo(this.map)
      })
    }
  }

  handleShow = () => {
    this.setState({
      showModal: true,
    })
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    })
  }

  //Need to figure out how to make this not byte code
  saveShapefile = async () => {
    // (optional) set names for feature types and zipped folder
    var options = {
      folder: 'shapefile',
      types: {
        polygon: 'shapefile',
      },
    }

    // a GeoJSON bridge for features

    // The sketched geometry of the polygon are for some reason in EPSG:3857 format and
    // need to be converted to WG84
    // var epsg =
    //   "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs";
    // var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
    var coords = []

    for (const [, value] of Object.entries(this.drawnItems._layers)) {
      value.getLatLngs()[0].forEach((element) => {
        coords.push(element)
      })
    }
    const longLat = coords.map((coords) => {
      //return proj4(epsg, wgs84, [coords["y"], coords["x"]]);
      return [coords['lng'], coords['lat']]
    })

    const shapefile = shpwrite.zip(
      {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [...longLat],
            },
            properties: {
              name: 'myForest',
            },
          },
        ],
      },
      options
    )
    return shapefile
  }

  b64toBlob = async (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    let blob = new Blob(byteArrays, { type: contentType })
    blob.name = 'shapefile.zip'
    return blob
  }

  componentDidMount() {
    if (this.props.forest) {
      this.props.getForestStats(this.props.forest.id)
      this.props.getForestAnalyses(this.props.forest.id)
    }
    this.createMap()
  }

  componentWillUnmount() {
    this.props.clearForests()
  }

  //search forest
  leafletControlSearch = () => {
    // Add search bar
    L.Control.geocoder({
      position: 'topleft',
    }).addTo(this.map)
  }

  /*   leafletAddBasemap = () => {

    var baselayers = {
        "Satellite": L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", 
            {
            attribution:
             "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
            }),
        "Streets": L.tileLayer(
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
            {
            attribution:
              "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
            }),
        "Topo": L.tileLayer(
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            {
            attribution:
             "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
            }),
    };

    var overlays = {};
    var options = { position: 'topleft' }
    L.control.layers(baselayers, overlays, options).addTo(this.map);
    baselayers["Satellite"].addTo(this.map);
  } */

  leafletChangeBasemap = (basemap) => {
    //delete tileLayer first
    var baselayers = {
      Satellite: L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        }
      ),
      Streets: L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        {
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        }
      ),
      Topo: L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        {
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        }
      ),
    }
    baselayers[basemap].addTo(this.map)
  }

  //info pop up after save forest name
  popUpNotification = () => {
    // Generate popup content based on layer type
    // - Returns HTML string, or null if unknown object
    var getPopupContent = function (layer) {
      var latlngs = layer._defaultShape
          ? layer._defaultShape()
          : layer.getLatLngs(),
        area = L.GeometryUtil.geodesicArea(latlngs)
      return L.GeometryUtil.readableArea(area, true)
    }

    // Object created - bind popup to layer, add to feature group
    this.map.on(L.Draw.Event.CREATED, (event) => {
      console.log(event)
      var layer = event.layer
      console.log(event.propagatedFrom)
      var content = getPopupContent(layer)
      if (content !== null) {
        layer.bindPopup('Area: ' + content)
        this.setState({
          forest_area: parseFloat(content),
        })
      }
      this.drawnItems.addLayer(layer)
      this.props.sendNotification(
        `Your forest area is ${this.state.forest_area} hectares`
      )
    })

    // Object(s) edited - update popups
    this.map.on(L.Draw.Event.EDITED, function (event) {
      var layers = event.layers,
        content = null
      layers.eachLayer(function (layer) {
        content = getPopupContent(layer)
        if (content !== null) {
          layer.setPopupContent(content)
        }
      })
    })
  }

  //delete layer
  leafletDeleteLayer = () => {
    this.map.on(L.Draw.Event.DELETED, (event) => {
      this.drawControl.setDrawingOptions({
        polygon: {
          allowIntersection: false,
          showArea: true,
        },
      })
      this.map.removeControl(this.drawControl)
      this.map.addControl(this.drawControl)
    })
  }

  addHomeAndZoom = (map, zoom) => {
    L.Control.zoomHome = L.Control.extend({
      options: {
        position: 'topleft',
        zoomInText: '+',
        zoomInTitle: 'Zoom in',
        zoomOutText: '-',
        zoomOutTitle: 'Zoom out',
        zoomHomeText: '<i class="fa fa-home" style="line-height:1.65;"></i>',
        zoomHomeTitle: 'Zoom home',
      },

      onAdd: function (map) {
        var controlName = 'gin-control-zoom',
          container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
          options = this.options

        this._zoomInButton = this._createButton(
          options.zoomInText,
          options.zoomInTitle,
          controlName + '-in',
          container,
          this._zoomIn
        )
        this._zoomOutButton = this._createButton(
          options.zoomOutText,
          options.zoomOutTitle,
          controlName + '-out',
          container,
          this._zoomOut
        )

        this._updateDisabled()
        map.on('zoomend zoomlevelschange', this._updateDisabled, this)

        return container
      },

      onRemove: function (map) {
        map.off('zoomend zoomlevelschange', this._updateDisabled, this)
      },

      _zoomIn: function (e) {
        this._map.zoomIn(e.shiftKey ? 3 : 1)
      },

      _zoomOut: function (e) {
        this._map.zoomOut(e.shiftKey ? 3 : 1)
      },

      _createButton: function (html, title, className, container, fn) {
        var link = L.DomUtil.create('a', className, container)
        link.innerHTML = html
        link.href = '#'
        link.title = title

        L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
          .on(link, 'click', L.DomEvent.stop)
          .on(link, 'click', fn, this)
          .on(link, 'click', this._refocusOnMap, this)

        return link
      },

      _updateDisabled: function () {
        var map = this._map,
          className = 'leaflet-disabled'

        L.DomUtil.removeClass(this._zoomInButton, className)
        L.DomUtil.removeClass(this._zoomOutButton, className)

        if (map._zoom === map.getMinZoom()) {
          L.DomUtil.addClass(this._zoomOutButton, className)
        }
        if (map._zoom === map.getMaxZoom()) {
          L.DomUtil.addClass(this._zoomInButton, className)
        }
      },
    })

    let zoomHome = new L.Control.zoomHome()
    zoomHome.addTo(map)
  }

  //init basemap
  createMap = () => {
    this.map = new L.Map(this.mapRef.current, {
      center: new L.LatLng(58.403128, 15.593688),
      zoom: 15,
      zoomControl: false,
      tap: false,
    })
    this.drawnItems = L.featureGroup().addTo(this.map)
    this.leafletControlSearch()
    this.leafletChangeBasemap('Satellite')

    L.control
      .scale({
        maxWidth: 250,
      })
      .addTo(this.map)

    this.addHomeAndZoom(this.map, this.map._zoom)

    L.control
      .locate({
        flyTo: true,
        returnToPrevBounds: true,
        keepCurrentZoomLevel: true,
        locateOptions: {
          enableHighAccuracy: true,
        },
      })
      .addTo(this.map)

    //shape map
    this.drawControl = new L.Control.Draw({
      position: 'topleft',
      edit: {
        featureGroup: this.drawnItems,
        poly: {
          allowIntersection: false,
        },
        remove: true,
      },
      draw: {
        polygon: {
          allowIntersection: false,
          showArea: true,
        },
        rectangle: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        marker: false,
      },
    })
    this.map.addControl(this.drawControl)

    this.popUpNotification()

    this.leafletDeleteLayer()

    // Get the shapefile for existing forest if there is any
    if (this.props.forest) {
      console.log(this.props.forest)
      this.props.getZipFileLeafleet(this.props.forest, this.addShapefileToMap)
    } else {
      this.setState({
        showLoadingModal: false,
      })
    }
  }

  //----------end of createmap()-------------------

  errorHandler = (error) => {
    console.log('ERROR UPLOADING')
    console.log(error)
  }

  addShapefileToMap = async (zipfile) => {
    //var geo = L.geoJson().addTo(this.map);

    shp(zipfile)
      .then((data) => {
        // For some reason the coords are flipped, so flip them back
        var coords = data.features[0].geometry.coordinates[0].map(
          (value, index, array) => {
            // console.log(value);
            return [value[1], value[0]]
          }
        )
        L.polygon(coords, { color: 'blue' }).addTo(this.drawnItems)

        this.map.fitBounds(this.drawnItems.getBounds())
        this.drawControl.setDrawingOptions({
          polygon: false,
        })
        this.map.removeControl(this.drawControl)
        this.map.addControl(this.drawControl)
        this.setState({
          showLoadingModal: false,
        })
      })
      .catch((err) => {
        console.log('Could not read the shapefile')
        console.log(err)
      })
  }

  zoomToLayer = () => {
    if (this.props.point_type === 'shape') {
      this.view.goTo(this.sourceGraphics).catch(function (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
        }
      })
    } else {
      this.view
        .goTo({
          center: [this.state.center_long, this.state.center_lat],
        })
        .then(() => {
          this.setState({
            showLoadingModal: false,
          })
        })
        .catch(function (error) {
          if (error.name !== 'AbortError') {
            console.error(error)
          }
        })
    }
  }

  // roundNumber = (number) => {
  //   return Math.round(number * 100) / 100;
  // };

  onSaveForest = () => {
    var forestName = this.state.forest_name
    // console.log(forestName)
    if (forestName === '' || this.state.forest_name === ' ') {
      this.props.sendErrorNotification('You need to name your forest!')
      return
    }
    // After all control has passed
    // Zoom in to the extent of the graphic

    var file = this.state.selectedFile

    if (file === null) {
      var hasGraphic = false
      try {
        hasGraphic = this.drawnItems !== null ? true : false
      } catch (error) {}
      if (!hasGraphic) {
        this.props.sendErrorNotification(
          'You need to draw a forest or upload a valid shapefile!'
        )
        return
      }
      try {
        this.map.fitBounds(this.drawnItems.getBounds())
      } catch (err) {
        console.log(err)
        this.props.sendErrorNotification(
          'You need to draw a forest or upload a valid shapefile!'
        )
        return
      }

      this.getScreenshot()
        .then((thumbnail) => {
          this.saveShapefile()
            .then((shapefile) => {
              this.b64toBlob(shapefile, 'application/x-zip-compressed')
                .then((shp_blob) => {
                  const name = forestName
                  const area = this.state.forest_area
                  const owner = this.props.user_id
                  let form = new FormData()
                  form.append('owner', owner)
                  form.append('forestname', name)
                  form.append('thumbnail', thumbnail, 'thumbnail.png')
                  form.append('shapefile', shp_blob, 'shapefile.zip')
                  form.append('size', area)
                  if (this.props.state.selectedGroup !== undefined) {
                    form.append(
                      'org_group',
                      this.props.state.selectedGroup.value
                    )
                  }

                  this.props
                    .addForest(form)
                    .then((res) => {
                      if (res.isAxiosError) {
                        var resp = res.response.data['forestname'][0]
                        console.log(res.response.data, res.response.status)
                        this.props.sendErrorNotification(resp)
                      } else {
                        this.props.sendNotification(
                          'Your forest has been saved!'
                        )
                        this.props.goBack()
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                })
                .catch((err) => {
                  console.log('Error making shp_blob', err)
                })
            })
            .catch((err) => {
              console.log('Error saving shapefile with error', err)
            })
        })
        .catch((err) => {
          console.log('Error saving a screenshot', err)
        })
    } else {
      this.getScreenshot()
        .then((thumbnail) => {
          const name = forestName
          const area = this.state.forest_area
          const owner = this.props.user_id
          const shapefile = file
          let form = new FormData()
          form.append('owner', owner)
          form.append('forestname', name)
          form.append('thumbnail', thumbnail, 'thumbnail.png')
          form.append('shapefile', shapefile, 'shapefile.zip')
          form.append('size', area)
          // this.props.addForest(form);
          this.props
            .addForest(form)
            .then((res) => {
              if (res.status !== 201) {
                // console.error(res, res.status)
                this.props.sendErrorNotification(res.data.forestname[0])
              } else {
                this.props.sendNotification('Your forest has been saved!')
                this.props.goBack()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log('Error saving a screenshot', err)
        })
    }
  }

  // handleShow = () => {
  //   this.setState({
  //     showModal: true,
  //   });
  // };

  // handleClose = () => {
  //   this.setState({
  //     showModal: false,
  //   });
  // };

  getScreenshot = async () => {
    // Read this: https://dev.to/gabiaxel/exporting-leaflet-map-to-image-in-the-browser-16am

    let blob = await fetch(imageURL).then((r) => r.blob())
    return blob
  }

  enableDrawPolygon = () => {
    this.drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        poly: {
          allowIntersection: false,
        },
        remove: true,
      },
      draw: {
        polygon: {
          allowIntersection: false,
          showArea: true,
        },
        rectangle: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        marker: false,
      },
    })

    new L.Draw.Polygon(this.map, this.drawControl.options.polygon).enable()
  }

  saveForestName = (name) => {
    this.setState({
      forest_name: name,
    })
  }
  render() {
    return (
      <div className={css.mainFrame}>
        <MapSettingSidenavRight
          {...this.props}
          searchForest={this.handleShow}
          forestStats={this.props.stats}
          onSaveForest={this.onSaveForest}
          enableDrawPolygon={this.enableDrawPolygon}
          hiddenFileInputRef={this.hiddenFileInputRef}
          onFileChange={this.onFileChange}
          forest={this.props.forest}
        />

        <MapSettingSidenavLeft
          {...this.props}
          analyses={this.props.analyses}
          addSHPToMap={this.addSHPToMap}
          analysisShp={this.props.analysisShp}
        />

        <NavOnMap
          {...this.props}
          handleShow={this.handleShow}
          saveForestName={this.saveForestName}
          leafletChangeBasemap={this.leafletChangeBasemap}
        />

        <MapWrapper
          mapRef={this.mapRef}
          className={css.mapWrapper}
          showLoadingModal={this.state.showLoadingModal}
        />

        <ForestInfoModal
          showLoadingModal={this.state.showLoadingModal}
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          forestStats={this.props.stats}
        />
      </div>
    )
  }
}

function MapWrapper(props) {
  return (
    <>
      <link
        rel='stylesheet'
        href='https://cdn.jsdelivr.net/npm/leaflet.locatecontrol/dist/L.Control.Locate.min.css'
      />
      <Fragment>
        <div className={css.webmap} ref={props.mapRef} />
        {props.showLoadingModal ? (
          <div className={css.loadingModalWrapper}>
            <div className={css.loadingModal}>
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />
              Loading the shape, please wait ...
            </div>
          </div>
        ) : (
          ' '
        )}
      </Fragment>
    </>
  )
}

//Map Sidenav with settings
const MapSettingSidenavRight = ({
  forestStats,
  shapeMapOnClick,
  onSaveForest,
  deleteLayerOnClick,
  enableDrawPolygon,
  hiddenFileInputRef,
  onFileChange,
  forest,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const settingButtons = {
    arrowOpen: <FaIcons.FaChevronRight />,
    arrowClose: <FaIcons.FaChevronLeft />,
  }

  const toggleSidebar = () => {
    setIsOpened(!isOpened)
  }

  const drawPolygon = () => {
    toggleSidebar()
    enableDrawPolygon()
  }

  const shpUploadClicked = () => {
    hiddenFileInputRef.current.click()
  }

  const fileUploaded = (e) => {
    toggleSidebar()
    onFileChange(e)
  }

  //Makes the string go from test_mc_test to Test Mc Test
  const humanize = (str) => {
    var i,
      frags = str.split('_')
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
  }

  const forestStuff = Object.keys(forestStats).map(function (key, index) {
    let statName
    let statValue
    switch (key) {
      //Hectar stuff
      case 'hectars_scanned':
      case 'size':
        statName = humanize(key)
        statValue = parseFloat(forestStats[key]).toFixed(2) + ' ha'
        break
      //Number of trees
      case 'spruce':
      case 'pine':
        statName = 'Number of ' + humanize(key) + 's'
        statValue = forestStats[key]
        break
      case 'leaf':
        statName = 'Number of ' + humanize(key) + ' trees'
        statValue = forestStats[key]
        break
      //Dates
      case 'date_created':
      case 'latest_analysis':
        statName = humanize(key)
        statValue = forestStats[key]
          ? forestStats[key].split('T')[0]
          : 'No analysis done yet'
        break
      //In case none of the above fit
      default:
        statName = humanize(key)
        statValue = forestStats[key]
    }
    return (
      <div key={key}>
        <p>{statName}</p>
        <h4>{statValue}</h4>
      </div>
    )
  })

  return (
    <>
      <div
        className={css.contentRight}
        style={{ right: isOpened ? '0' : '-300px' }}
        backdrop='static'
        keyboard='false'
      >
        <div className={css.buttonRight} onClick={toggleSidebar}>
          {isOpened ? settingButtons.arrowOpen : settingButtons.arrowClose}
        </div>

        {/* <form className={css.findForest}>
                <input type="text" placeholder="Find your forest"/>
                <BiIcons.BiSearch 
                className={css.searchIcon}
                onClick={searchForest}
                />
            </form> */}

        {/* <hr 
            style={{margin: '0.2rem 10%',
            borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)', 
            opacity: 0.5}}/> */}

        {/* tools in sidebar */}
        {forest ? (
          <div className={css.stats}>
            <h5>Forest stats</h5>
            <hr
              style={{
                margin: '0 0 5px 0',
                borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)',
                opacity: 0.5,
              }}
            />
            {/* Dragons */}
            {forestStuff}
            {/* <p>{`The cost of analysis is`}</p>
              <h4>{`${Math.round((forestStats.size*pricePerHectar + Number.EPSILON) * 100) / 100}`}<span>{`kr`}</span></h4>
              <p>{`The cost of analysis with a drone pilot is`}</p>
              <h4>{`${Math.round(((forestStats.size*(pricePerHectar+40)+1000) + Number.EPSILON) * 100) / 100}`}<span>{`kr`}</span></h4> */}
          </div>
        ) : (
          <div className={css.tools}>
            <h5>Tools</h5>
            <hr
              style={{
                margin: '0 0 8px 0',
                borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)',
                opacity: 0.5,
              }}
            />
            <div className={css.toolsGroup}>
              {/* upload shape button */}
              <div className={css.toolContainer} onClick={shpUploadClicked}>
                <BiIcons.BiCloudUpload className={css.icons} />
                <h6>Upload Shape</h6>
                <input
                  type='file'
                  onChange={(e) => fileUploaded(e)}
                  style={{ display: 'none' }}
                  ref={hiddenFileInputRef}
                  accept='zip,application/zip,application/x-zip,application/x-zip-compressed'
                />
              </div>
              {/* draw shape button */}
              <div className={css.toolContainer} onClick={drawPolygon}>
                <BiIcons.BiShapeTriangle className={css.icons} />
                <h6>Draw Shape</h6>
              </div>
              <div
                className={css.toolContainer}
                onClick={onSaveForest}
                style={{ marginTop: '5px' }}
              >
                <BiIcons.BiSave className={css.icons} />
                <h6>Save Forest</h6>
              </div>
            </div>

            {/* save button */}
            <div className={css.toolsGroup}>
              {/* edit layer button */}
              <div className={css.toolContainer} onClick={toggleSidebar}>
                <BiIcons.BiEdit className={css.icons} />
                <h6>Edit Layer</h6>
              </div>
              {/* delete button */}
              <div className={css.toolContainer} onClick={toggleSidebar}>
                <AiIcons.AiOutlineDelete className={css.icons} />
                <h6>Delete Layer</h6>
              </div>
            </div>
          </div>
        )}

        {/* forest information: displayed after map is shaped */}
        {/* OLD */}
        {/* <div className={css.tools}>
            <h5>Forest Info</h5>
            <hr 
            style={{
            margin: '0 0 5px 0',
            borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)', 
            opacity: 0.5}}/> 
            <p>{`Your forest is`}</p>
            <h4>{`${forestStats.size}`}<span>{`hectars`} </span></h4>
            
            <p>{`The cost of analysis is`}</p>
            <h4>{`${Math.round((forestStats.size*pricePerHectar + Number.EPSILON) * 100) / 100}`}<span>{`kr`}</span></h4>

              {/* Hmm sus  */}
        {/* <p>{`The cost of analysis with a drone pilot is`}</p>
            <h4>{`${Math.round(((forestStats.size*80+1000) + Number.EPSILON) * 100) / 100}`}<span>{`kr`}</span></h4>
          </div> */}
      </div>

      {isOpened ? (
        <div className={css.overlayRight} onClick={toggleSidebar} />
      ) : (
        ''
      )}
    </>
  )
}

const MapSettingSidenavLeft = ({ analyses, addSHPToMap, analysisShp }) => {
  const [isOpened, setIsOpened] = useState(false)
  const settingButtons = {
    arrowOpen: <FaIcons.FaChevronRight />,
    arrowClose: <FaIcons.FaChevronLeft />,
  }

  const toggleSidebar = () => {
    setIsOpened(!isOpened)
  }

  const analysisClicked = (analysis) => {
    let id = analysis['id']
    console.log(id)
    analysisShp(id).then((res) => {
      console.log(res)
      if (res.isAxiosError) {
        console.error(res.response.statusText, res.response.status)
        this.props.sendErrorNotification(res.response.statusText)
      } else {
        var blob = res.data
        blob
          .arrayBuffer()
          .then(async (buffer) => {
            await addSHPToMap(buffer)
          })
          .catch((err) => {
            console.log(err)
            this.props.sendErrorNotification(err)
          })
      }
    })
  }

  const forestAnalyses = Object.keys(analyses).map(function (key) {
    let analysis = analyses[key]
    let displayName
    let analysisName = analysis['analysis_name']
    var correct_time = convertUTCDateToLocalDate(analysis['date_created'])
    var analysisDate = correct_time.split('.')[0].split('T')[0]
    analysisName === '' ||
    analysisName === 'Empty' ||
    analysisName === null ||
    analysisName === 'None'
      ? (displayName = analysisDate)
      : (displayName = analysisDate + ' ' + analysisName)
    return (
      <div
        className={css.toolContainer}
        key={key}
        onClick={() => analysisClicked(analysis)}
      >
        {/* <BiIcons.BiCloudUpload 
        className={css.icons}/> */}
        <h7>{displayName}</h7>
      </div>
    )
  })

  // Just hides the component for now
  let hide = true
  if (hide) return null

  return (
    <>
      {analyses && (
        <div
          className={css.contentLeft}
          style={{ left: isOpened ? '0' : '-300px' }}
          backdrop='static'
          keyboard='false'
        >
          <div className={css.buttonLeft} onClick={toggleSidebar}>
            {isOpened ? settingButtons.arrowClose : settingButtons.arrowOpen}
          </div>
          <div className={css.tools}>
            <h5>Analyses</h5>
            <hr
              style={{
                margin: '0 0 8px 0',
                borderBottom: '0.5px solid rgba(231, 231, 231, 0.4)',
                opacity: 0.5,
              }}
            />
            <div className={css.toolsGroup}>{forestAnalyses};</div>
          </div>
        </div>
      )}

      {isOpened ? (
        <div className={css.overlayLeft} onClick={toggleSidebar} />
      ) : (
        ''
      )}
    </>
  )
}

//NEW SUB COMPONENTS
class NavOnMap extends Component {
  constructor(props) {
    super(props)
    this.forestNameRef = React.createRef()
    this.state = {
      matches: window.matchMedia('(min-width: 768px)').matches,
      showMapType: false,
      forestName: '',
      isShowingForestNameTooltip: false,
    }
  }

  toggleShowMapType = () => {
    this.setState({
      showMapType: !this.state.showMapType,
    })
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches })
    window.matchMedia('(min-width: 768px)').addListener(handler)
  }

  onForestNameChanged = (event) => {
    if (event.target.value === '') {
      this.hideForestNameTooltip()
    } else if (!event.target.value.match(/^[A-Za-z0-9äöå\s]+$/i)) {
      this.showForestNameTooltip()
      // this.setState({
      //   forestName: "",
      // });
      this.props.saveForestName('')
      return
    } else {
      this.hideForestNameTooltip()
    }
    this.props.saveForestName(event.target.value)
    // this.setState({
    //   forestName: event.target.value,
    // });
  }
  showForestNameTooltip = () => {
    this.setState({
      isShowingForestNameTooltip: true,
    })
  }

  hideForestNameTooltip = () => {
    this.setState({
      isShowingForestNameTooltip: false,
    })
  }

  render() {
    // const { toggleSidebar } = this.props;

    return (
      <>
        <div className={css.navOnMap}>
          {/* buttons below navbar */}

          <div className={css.nameForest}>
            <div className={css.backButton} onClick={this.props.goBack}>
              <i
                className='fas fa-chevron-left'
                style={{ paddingRight: '0.5rem' }}
              ></i>
            </div>

            {/* forest name input */}
            <Form.Control
              ref={this.forestNameRef}
              type='text'
              // placeholder="Name of your forest..."
              aria-label='Name of your forest...'
              placeholder={
                this.props.forestName
                  ? this.props.forestName
                  : 'Name of your forest'
              }
              aria-describedby='basic-addon2'
              onChange={this.onForestNameChanged}
              // onClick={toggleSidebar}
            />
            <Overlay
              target={this.forestNameRef.current}
              show={this.state.isShowingForestNameTooltip}
              placement='right'
            >
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  Only letters and numbers
                </Tooltip>
              )}
            </Overlay>

            {/* save button */}
            {/* <div 
              className={css.saveIcon}
              onClick={this.props.onSaveForest}>
              <i class="far fa-save"></i>
            </div> */}
          </div>

          {/* different map types */}
          <div onClick={this.toggleShowMapType} className={css.mapType}>
            {
              <div className={css.mapTypeClose}>
                <BiIcons.BiMapAlt />
              </div>
            }
          </div>

          {this.state.showMapType ? (
            <div className={css.mapType}>
              <div className={css.mapTypeOpen}>
                <div
                  className={css.mapHeader}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                  }}
                >
                  <h4
                    style={{
                      paddingRight: '50px',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <strong>Map Type</strong>
                  </h4>
                  <IoIcons.IoIosCloseCircle
                    style={{
                      height: '25px',
                      width: '25px',
                      display: 'block',
                    }}
                    className={css.closeIcon}
                    onClick={this.toggleShowMapType}
                  />
                </div>

                <div
                  className={css.mapsContainer}
                  style={{ textAlign: 'center' }}
                >
                  <div className={css.mapGroup}>
                    <div
                      className={css.map}
                      onClick={() =>
                        this.props.leafletChangeBasemap('Satellite')
                      }
                    >
                      <div className={css.icon}>
                        <i className='fas fa-satellite fa-3x icon' />
                      </div>
                    </div>
                    <h6>Satellite</h6>
                  </div>

                  <div className={css.mapGroup}>
                    <div
                      className={css.map}
                      onClick={() => this.props.leafletChangeBasemap('Streets')}
                    >
                      <div className={css.icon}>
                        <i className='fas fa-road fa-3x icon'></i>
                      </div>
                    </div>
                    <h6>Street</h6>
                  </div>

                  <div className={css.mapGroup}>
                    <div
                      className={css.map}
                      onClick={() => this.props.leafletChangeBasemap('Topo')}
                    >
                      <div className={css.icon}>
                        <i className='fas fa-mountain fa-3x icon' />
                      </div>
                    </div>
                    <h6>Topo</h6>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.user,
  stats: state.myforest.stats,
  analyses: state.myforest.analyses,
})

export default connect(mapStateToProps, {
  sendNotification,
  sendErrorNotification,
  addForest,
  getZipFileLeafleet,
  clearForests,
  getForestStats,
  getForestAnalyses,
  analysisShp,
})(LeafletMapMyForest)
